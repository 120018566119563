// LINKS
const navC = document.querySelector('a[href="#nav-contact"]');
const navP = document.querySelector('a[href="#nav-projects"]');
const about1 = document.querySelector('a[href="#about1"]');
const about2 = document.querySelector('a[href="#about2"]');
const navP1 = document.getElementById("link-work");
const navX1 = document.getElementById("close1");
const navX2 = document.getElementById("close2");

// SECTIONS
const sectionA1 = document.querySelector('#about1');
const sectionA2 = document.querySelector('#about2');
const sectionP = document.querySelector('#nav-projects');
const sectionC = document.querySelector('#nav-contact');

// CLICKS
let clickCount = 0;



sectionA2.classList.add('hidden');
sectionP.classList.add('none');
sectionC.classList.add('none');

navC.addEventListener('click', () => {
  // ABOUT 1
  sectionA1.classList.add('hidden');
  sectionA1.classList.add('none');

  // ABOUT 2
  sectionA2.classList.remove('hidden');
  sectionA2.classList.add('none');

  // NAV CONTACT
  sectionC.classList.remove('none');

  // NAV PROJECT
  sectionP.classList.add('none');
});

navX1.addEventListener('click', () => {
  // ABOUT 1
  sectionA1.classList.remove('none');
  sectionA1.classList.remove('hidden');

  // ABOUT 2
  sectionA2.classList.add('hidden');
  sectionA2.classList.remove('none');

  // NAV PROJECTS
  sectionP.classList.add('none');

  // NAV CONTACT
  sectionC.classList.add('none');
});

navP.addEventListener('click', () => {
  // ABOUT 1
  sectionA1.classList.add('none');

  // ABOUT 2
  sectionA2.classList.remove('hidden');
  sectionA2.classList.add('none');

  // NAV PROJECTS
  sectionP.classList.remove('none');

  // NAV CONTACT
  sectionC.classList.add('none');

});

navX2.addEventListener('click', () => {
  // ABOUT 1
  sectionA1.classList.remove('none');
  sectionA1.classList.remove('hidden');

  // ABOUT 2
  sectionA2.classList.add('hidden');
  sectionA2.classList.remove('none');

  // NAV PROJECTS
  sectionP.classList.add('none');

  // NAV CONTACT
  sectionC.classList.add('none');
});

about1.addEventListener('click', () => {
  // ABOUT 1
  sectionA1.classList.remove('hidden');

  // ABOUT 2
  sectionA2.classList.add('hidden');
});

about2.addEventListener('click', () => {
  // ABOUT 1
  sectionA1.classList.add('hidden');

  // ABOUT 2
  sectionA2.classList.remove('hidden');
});






// navP1.addEventListener("click", function () {
//   clickCount++;

//   if (clickCount === 2) {
//     navP1.href = "./#nav-norm";
//     if (navP1.href === "#nav-norm") {
//       navP1.href = "./#link-work";
//     } else {
//       navP1.href = "#nav-norm";
//     }
//     clickCount = 0;

//     // ABOUT 1
//     sectionA1.classList.remove('hidden');
//     sectionA1.classList.remove('none');

//     // ABOUT 2
//     sectionA2.classList.add('hidden');
//     sectionA2.classList.remove('none');

//     // NAV PROJECTS
//     sectionP.classList.add('hidden');
//     sectionP.classList.add('none');

//     // NAV CONTACT
//     sectionC.classList.add('none');
//   }
// });

